<template>
  <!-- <vs-button :text="userID" @click="userID === 'abcd' ? userID = 'dcba' : userID = 'abcd'" /> -->
  <vs-chat
    class="left-0 top-0 h-full"
    :avatar="avatar"
    :rooms="rooms"
    :forcedCurrentRoomID="currentRoomID"
    :messages="messages"
    :hideMessageLoader="hideMessageLoader[currentRoomID]"
    @room:change="onRoomChange"
    @chat:send="onChatSend"
    @chat:loadMessages="onLoadMessages"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed, Ref, watch, inject } from 'vue'
import avatar from '@/assets/avatar.jpg'
import { useSubscription, gql, useClientHandle, useQuery } from '@urql/vue'
import { pipe, subscribe } from 'wonka'
import axios from 'axios'

const getRooms = gql`
  subscription ($userID:String) {
  rooms(where: {_or: [
    {instanceID: {_eq: $userID}},
    {userID: {_eq: $userID}}
  ]}, order_by: {messages_aggregate: {max: {time: desc}}}) {
    id
    name
    userID
    instanceID
    instanceLastSeen
    instance {
      id
      name
    }
    user {
      name
    }
    messages(order_by: {time: desc}, limit: 1) {
      id
      userID
      user {
        name
      }
      time
      content
      contentType
    }
  }
}
`

const getMessages = gql`
  query ($roomID:String, $offset:Int, $limit:Int) {
  messages(where: {roomID: {_eq: $roomID}}, order_by: {time: desc}, limit: $limit, offset: $offset) {
    id
    userID
    user {
      name
    }
    time
    content
    contentType
  }
}
`

const subscribeUnread = gql`
  subscription ($userID:String, $roomID:String, $lastSeen:timestamp) {
  rooms(where: {id: {_eq: $roomID}}, order_by: {messages_aggregate: {max: {time: desc}}}) {
    messages_aggregate(where: {_and: {userID: {_neq: $userID}, time: {_gt: $lastSeen}}}) {
      aggregate {
        count
      }
    }
  }
}
`

export default defineComponent({
  name: 'Table',
  components: {
  },
  setup() {
    
    const userID = ref('abcd')
    const clientHandle = useClientHandle()
    const variablesRef = ref({
      userID,
    })
    const subscriptionResult = useSubscription({
      query: getRooms,
      variables: variablesRef
    })
    const rooms = ref([])
    const loadedMessages: Ref<Record<string, unknown>> = ref({})
    const currentRoomID = ref()
    const hideMessageLoader = ref({})
    const queriedRooms = []
    const messagesPerQuery = 10
    const subscribedRoomIDs = {}
    let unsubscribe = {}
    watch(subscriptionResult.data, async (data) => {
      const roomsQuery = data && data['rooms']
      rooms.value = Object.keys(roomsQuery).map(index => {
        const room = roomsQuery[index]
        const currentRoomMessages = loadedMessages.value[currentRoomID.value] || [{}]
        const lastMessageID = currentRoomMessages[0].id
        if (room.messages && room.messages[0].id !== lastMessageID) {
          handleMessages(room.messages, room.id , true)
        }
        const beforeRoomIndex = rooms.value.findIndex(searchedRoom => room.id === searchedRoom.id)
        const beforeRoom = rooms.value[beforeRoomIndex]
        if (!subscribedRoomIDs[room.id] || room.instanceLastSeen !== beforeRoom?.instanceLastSeen) {
          console.info(unsubscribe)
          if (unsubscribe[room.id]) {
            unsubscribe[room.id]()
          }
          const subscription = pipe(clientHandle.client.subscription(subscribeUnread, { userID: userID.value, roomID: room.id, lastSeen: room.instanceLastSeen }),
            subscribe(result => {
              subscribedRoomIDs[room.id] = true
              if (result.data.rooms.length) {
                let unread = 0
                if (currentRoomID.value !== room.id) {
                  unread = result.data.rooms[0].messages_aggregate?.aggregate.count
                }
                if (beforeRoomIndex !== -1 && rooms.value[beforeRoomIndex]) {
                  rooms.value[beforeRoomIndex].unread = unread
                }
              }
            }))
            unsubscribe[room.id] = subscription.unsubscribe
        }
        return {
          id: room.id,
          name: room.name,
          time: room.messages[0].time,
          content: room.messages[0].content,
          unread: rooms.value[index]?.unread,
          instanceLastSeen: room.instanceLastSeen,
          avatar
        }
      })
      if (!currentRoomID.value) {
        onRoomChange(rooms.value[0].id)
      }
    })
    const messages = computed(() => {
      if (!currentRoomID.value) {
        return []
      }
      return loadedMessages.value[currentRoomID.value]
    })
    const handleMessages = (messages, roomID, isSubscription) => {
      const newMessages = messages.map(message => {
        if (isSubscription) {
          if (!loadedMessages.value[roomID]) {
            loadedMessages.value[roomID] = []
          }
          const pendingIndex = loadedMessages.value[roomID].findIndex(existingMessage => existingMessage.id = message.content && !existingMessage.delivered)
          if (pendingIndex !== -1) {
            loadedMessages.value[roomID].splice(pendingIndex, 1)
          }
        }
        // console.info(isSubscription,roomID , currentRoomID.value)
        // if (isSubscription && (roomID === currentRoomID.value)) {
        //   console.info('here')
        //   updateLastSeen(roomID)
        // }
        return {
          id: message.id,
          content: message.content,
          time: message.time,
          delivered: true,
          me: userID.value === message.userID,
        }
      })
      if (newMessages.length < messagesPerQuery) {
        hideMessageLoader.value[roomID] = true
      }
      loadedMessages.value[roomID] = isSubscription ? [...newMessages, ...loadedMessages.value[roomID]] : [...loadedMessages.value[roomID] , ...newMessages]
    }
    const onRoomChange = async (roomID) => {
      const roomIndex = rooms.value.findIndex(searchedRoom => roomID === searchedRoom.id)
      rooms.value[roomIndex].unread = 0
      updateLastSeen(roomID)
      currentRoomID.value = roomID
      if (!loadedMessages.value[roomID]) {
        loadedMessages.value[roomID] = []
      }
      if (queriedRooms.indexOf(roomID) === -1) {
        const queryResult = await clientHandle.client.query(getMessages, { roomID, offset:0, limit: messagesPerQuery }).toPromise()
        handleMessages(queryResult.data.messages, roomID, false)
        queriedRooms.push(roomID)
      }
    }
    let offset = {}
    const onLoadMessages = async() => {
      console.info('load more')
      if (!offset[currentRoomID.value]) {
        offset[currentRoomID.value] = messagesPerQuery
      }
      const queryResult = await clientHandle.client.query(getMessages, { roomID: currentRoomID.value, offset: offset[currentRoomID.value], limit: messagesPerQuery }).toPromise()
      offset[currentRoomID.value] += messagesPerQuery
      handleMessages(queryResult.data.messages, currentRoomID.value, false)
    }
    const onChatSend = async (e) => {
      loadedMessages.value[currentRoomID.value] = [
        {
          content: e,
          time: new Date(),
          delivered: false,
          me: true,
          id: e
        },
        ...loadedMessages.value[currentRoomID.value],
        
      ]
      const url = `http://localhost:5001/rs-public/us-central1/api/mutation`
      const response = await axios.post(url, {
        action: 'insert',
        table: 'messages',
        data: {
          roomID: currentRoomID.value,
          userID: userID.value,
          content: e,
        }
      }, {timeout: 1000000})
    }
    const updateLastSeen = async (roomID) => {
      const url = `http://localhost:5001/rs-public/us-central1/api/mutation`
      console.info('last seen', new Date())
      const response = await axios.post(url, {
        action: 'insert',
        table: 'rooms',
        data: {
          id: roomID,
          instanceLastSeen: new Date(),
        }
      }, {timeout: 1000000})
    }
    return { userID, rooms, currentRoomID, hideMessageLoader, messages, onRoomChange, onChatSend, onLoadMessages }
  }
})
</script>
