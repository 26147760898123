<template>
<div class="p-5">
  <vs-card>
    <div class="text-xl font-bold mb-5">
      Formulario de inscripción
    </div>
    <vs-input
      v-if="!documentJSON"
      class="ml-2 mb-1 w-60"
      name="documentID"
      label="Documento alumno"
      v-model="documentID"
    />
    <vs-form
      v-if="!documentJSON"
      class="mb-10" :fields="fields"
      submit-text="Generar contrato"
      @form:submit="submit"
    />
    <vs-editor
      :class="documentJSON ? '' : 'hidden'"
      :editable="false"
      v-model="documentJSON"
      @update:content="updateHTML"
    />
    <div
      class="my-4"
      v-if="documentJSON"
    >
      <vs-upload
        @upload:file="handleFile"
      />
      <vs-button
      text="Firmar contrato"
      :isBusy="isBusy"
      @click="generatePDF()"
    />
    </div>
  </vs-card>
</div>
</template>


<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import contract from '../utils/contract.js'
import axios from 'axios'

export default defineComponent({
  name: 'Dashboard',
  components: {
  },
  setup() {
    const fields = ref([
      {
        name: 'nombrealumno',
        label: 'Nombre alumno',
        type: 'text',
        rules: 'required',
      },
      {
        name: 'apellidosalumno',
        label: 'Apellidos',
        type: 'text',
        rules: 'required'
      },
      {
        name: 'grado',
        label: 'Grado',
        type: 'text',
        rules: 'required'
      },
      {
        name: 'colegio',
        label: 'Colegio',
        type: 'text',
        rules: 'required'
      },
      {
        name: 'tipo',
        label: 'Tipo de servicio',
        type: 'select',
        rules: 'required',
        options: [
          'Tiempo completo',
          'Medio tiempo AM',
          'Medio tiempo PM',
          'Extraclases'
        ]
      },
      {
        placeholder: '',
        label: 'Dirección recogida',
        type: 'text',
        rules: 'required'
      },
      {
        placeholder: '',
        label: 'Dirección llegada',
        type: 'text',
        rules: 'required'
      },
      {
        name: 'nombrepadre',
        label: 'Nombre completo acudiente',
        type: 'alpha',
      },
      {
        name: 'documentopadre',
        label: 'Documento acudiente',
        type: 'numeric',
      },
      {
        name: 'celularpadre',
        label: 'Celular',
        type: 'tel',
      },
      {
        name: 'emailpadre',
        label: 'email 1',
        type: 'email',
      },
      {
        name: 'emailmadre',
        label: 'email 2',
        type: 'email',
      },
    ])
    const signature = ref()
    const getBase64 = (file) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        signature.value = reader.result
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
    const handleFile = (files:any) => {
      getBase64(files[0])
    }
    const replaceAll = (str, find, replace) => {
      return str.replace(new RegExp(find, 'g'), replace)
    }
    const documentHTML = ref('')
    const updateHTML = (content) => {
      documentHTML.value = content.html
    }
    const documentJSON = ref('')
    const submit = (values) => {
      let replacedJSON = JSON.stringify(JSON.parse(contract.json))
      for (const field of Object.keys(values)) {
        replacedJSON = replaceAll(replacedJSON, `{"type":"mention","attrs":{"id":"${field}","label":null}}`, `{"type":"text","marks":[{"type":"bold"}],"text":"${values[field] || ' '}"}`)
      }
      documentJSON.value = JSON.parse(replacedJSON)
    }
    const isBusy = ref(false)
    const generatePDF = async () => {
      isBusy.value = true
      documentHTML.value = replaceAll(documentHTML.value, '<span data-type="mention" class="rounded-sm bg-primary-container text-on-primary-container px-1" data-id="firma">@firma</span>', `<img src="${signature.value}">`)
      const response = await axios.post('pdf',
        {
          html: documentHTML.value,
        },
        {
          responseType: 'blob'
        }
      )
      isBusy.value = false
      var url = window.URL.createObjectURL(response.data)
      var a = document.createElement('a')
      a.href = url
      a.download = 'test.pdf'
      a.click()
      a.remove()
      setTimeout(() => window.URL.revokeObjectURL(url), 100)
    }
    const documentID = ref('')
    watch(
      documentID, () => {
        if (documentID.value === '1017214297') {
          fields.value = [
            {
              name: 'nombrealumno',
              label: 'Nombre alumno',
              type: 'text',
              rules: 'required',
              value: 'Geronimo'
            },
            {
              name: 'apellidosalumno',
              label: 'Apellidos',
              type: 'text',
              rules: 'required',
              value: 'Aristizabal Duque'
            },
            {
              name: 'grado',
              label: 'Grado',
              type: 'text',
              rules: 'required',
              value: '5A'
            },
            {
              name: 'colegio',
              label: 'Colegio',
              type: 'text',
              rules: 'required',
              value: 'The Columbus School'
            },
            {
              name: 'tipo',
              label: 'Tipo de servicio',
              type: 'select',
              rules: 'required',
              options: [
                'Tiempo completo',
                'Medio tiempo AM',
                'Medio tiempo PM',
                'Extraclases'
              ],
              value: 0
            },
            {
              placeholder: '',
              label: 'Dirección recogida',
              type: 'text',
              rules: 'required',
              value: 'Calle 9A Sur #11-111'
              
            },
            {
              placeholder: '',
              label: 'Dirección llegada',
              type: 'text',
              rules: 'required',
              value: 'Calle 9A Sur #11-111'
            },
            {
              name: 'nombrepadre',
              label: 'Nombre completo acudiente',
              type: 'alpha',
              value: 'John Jairo Aristizábal Duque'
            },
            {
              name: 'documentopadre',
              label: 'Documento acudiente',
              type: 'numeric',
              value: 70108249
            },
            {
              name: 'telefonopadre',
              label: 'Teléfono',
              type: 'tel',
              value: 3128769985
            },
            {
              name: 'celularpadre',
              label: 'Celular',
              type: 'tel',
              value: 3128769985
            },
            {
              name: 'emailpadre',
              label: 'Email 1',
              type: 'email',
              value: 'g@isaga.co'
            },
            {
              name: 'emailmadre',
              label: 'Email 2',
              type: 'email',
              value: 'g@isaga.co'
            },
          ]
        }
      }
    )
    return { contract, handleFile, fields, updateHTML, documentJSON, submit, isBusy, signature, generatePDF, documentID }
  }
})
</script>
