import w from '@/assets/bus/w.svg'
import sw from '@/assets/bus/sw.svg'
import s from '@/assets/bus/s.svg'
import se from '@/assets/bus/se.svg'
import e from '@/assets/bus/e.svg'
import ne from '@/assets/bus/ne.svg'
import n from '@/assets/bus/n.svg'
import nw from '@/assets/bus/nw.svg'
export default {
  w,
  sw,
  s,
  se,
  e,
  ne,
  n,
  nw
}

