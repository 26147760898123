<template>
  <!-- <div class="w-full flex justify-end">
    <img alt="bus" src="@/assets/bus.svg" width="120"/>
  </div> -->
  <div class="mx-10 mt-5">
    <vs-table
      searchbar
      :rows="rows"
      :columns="columns"
      :actions="actions"
      enableCreation
      enableEdition
      enableDeletion
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef, computed, Ref, watch, ref } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import tableStructures from '@/utils/tableStructures'
import { useSubscription, gql, useClientHandle, useQuery } from '@urql/vue'

const subscribeRoutes = gql`
  subscription($instanceID: String) {
    routes(where: {instanceID: {_eq: $instanceID}}, order_by: {name: desc}) {
      id
      name
      vehicle {
        id
        name
        capacity
      }
      contactName_contactPhoneNumber
      tags {
        tag {
          id
          name
          color
        }
      }
    }
  }
`

const subscribeParcels = gql`
  subscription($instanceID: String) {
    parcels(where: {instanceID: {_eq: $instanceID}}, order_by: {name: desc}) {
      id
      name_documentID
      stops {
        id
        address_hint
      }
      tags {
        tag {
          id
          name
          color
        }
      }
    }
  }
`

const subscribeVehicles = gql`
  subscription($instanceID: String) {
    vehicles(where: {instanceID: {_eq: $instanceID}}, order_by: {name: desc}) {
      id
      name
      capacity
      tags {
        tag {
          id
          name
          color
        }
      }
    }
  }
`

export default defineComponent({
  name: 'Table',
  components: {
  },
  setup() {
    const variablesRef = ref({
      instanceID: 'isaga',
    })
  
    const routeSubscription = useSubscription({
      query: subscribeRoutes,
      variables: variablesRef
    })
    const routes = ref()
    watch(routeSubscription.data, (data) => {
      const routeQuery = data && data.routes
      routes.value = Object.keys(routeQuery).map(index => {
        const route = routeQuery[index]
        return {
          id: route.id,
          name: route.name,
          plate: route.vehicle.name,
          occupancy: 20,
          'contactName_contactPhoneNumber': route.contactName_contactPhoneNumber,
          tags: route.tags.map(tag => tag.tag.name).join(',')
        }
      })
    })
    const parcelSubscription = useSubscription({
      query: subscribeParcels,
      variables: variablesRef
    })
    const parcels = ref()
    watch(parcelSubscription.data, (data) => {
      const parcelQuery = data && data.parcels
      parcels.value = Object.keys(parcelQuery).map(index => {
        const parcel = parcelQuery[index]
        return {
          name_documentID: parcel.name_documentID,
          address_hint:parcel.stops[0].address_hint,
          tags: parcel.tags.map(tag => tag.tag.name).join(',')
        }
      })
    })
    const vehicleSubscription = useSubscription({
      query: subscribeVehicles,
      variables: variablesRef
    })
    const vehicles = ref()
    watch(vehicleSubscription.data, (data) => {
      const vehicleQuery = data && data.vehicles
      vehicles.value = Object.keys(vehicleQuery).map(index => {
        const vehicle = vehicleQuery[index]
        console.info(vehicle)
        return {
          name: vehicle.name,
          capacity: vehicle.capacity,
          gps: true,
          tags: vehicle.tags.map(tag => tag.tag.name).join(',')
        }
      })
      console.info(vehicles.value)
    })
    const route = useRoute()
    const tableID = computed(() => route.params.tableID)
    let rows = computed(() => {
      if (tableID.value === 'routes') {
        return routes
      }
      if (tableID.value === 'parcels') {
        console.info(parcels)
        return parcels
      }
      if (tableID.value === 'vehicles') {
        return vehicles
      }
    })
    const columns = computed(() => {
      return tableStructures[tableID.value]?.columns || {}
    })
    let actions = shallowRef(tableStructures[tableID.value]?.actions || {})
    return { rows, columns, actions }
  }
})
</script>
