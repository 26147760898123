<template>
  <div class="h-screen relative">
    <keep-alive>
      <div class="inline-block w-full h-[calc(100%-132px)]">
        <vs-map
          class="relative"
          :markers="markers"
          :paths="paths"
        >
        </vs-map>
      </div>
    </keep-alive>
    <div class="absolute w-full flex flex-col bottom-0 items-baseline">
      <div v-if="!expanded" class="bg-primary text-on-primary rounded py-1 px-2 ml-1 mb-1 left-0 right-0 text-left relative pr-10">
        <div class="text-sm">
          Geronimo ingreso al transporte
        </div>
        <div class="absolute bottom-1 right-1 text-xs font-semilight">
          3:55
        </div>
      </div>
      <div class="bg-surface z-10 w-full rounded-sm text-on-surface transition-all flex flex-col">
        <div class="flex flex-col">
        <!-- <div class="bg-black bg-opacity-50 h-1 w-8 mt-2 rounded-full mx-auto" @click="expanded = !expanded" /> -->
          <vs-icon :name="expanded ? 'akar-icons:chevron-down' : 'akar-icons:chevron-up'" class="cursor-pointer mx-auto" @click="expanded = !expanded"/>
          <div class="flex items-center h-full ml-2">
            <div class="relative w-full">
              <div class="font-bold text-md w-full h-5 text-left">
                Ruta 25
              </div>
              <div class="text-xs w-full opacity-75 text-left pb-2">
                Ubicación actualizada a las 9:18
              </div>
            </div>
            <vs-button class="" iconName="bx:bxs-phone-call" />
          </div>
        </div>  
        <div v-if="expanded" class="p-2 bg-surface-variant max-h-20 overflow-y-auto opacity-90 flex flex-col-reverse">
          <div class="flex justify-start items-center">
            <vs-indicator color="primary" :size="5" />
            <div class="mx-2 text-xs">
              La ruta 25 comenzó su recorrido
            </div>
          </div>
          <div class="flex justify-start items-center">
            <vs-indicator color="primary" :size="5" />
            <div class="mx-2 text-xs">
              La ruta 25 comenzó su recorrido
            </div>
          </div>
          <div class="flex justify-start items-center">
            <vs-indicator color="primary" :size="5" />
            <div class="mx-2 text-xs">
              La llegará en aproximadamente 5 minutos.
            </div>
          </div>
          <div class="flex justify-start items-center">
            <vs-indicator color="primary" :size="5" />
            <div class="mx-2 text-xs">
              Gerónimo y Daniel ingresaron al transporte
            </div>
          </div>
          <div class="flex justify-start items-center">
            <vs-indicator color="primary" :size="5" />
            <div class="mx-2 text-xs">
              Gerónimo y Daniel llegaron al colegio.
            </div>
          </div>
        </div>
        <div class="flex" >
          <div class="bar inline-flex w-full overflow-x-auto bg-surface border ">
            <div class="flex flex-col items-center mx-2">
              <div class="relative">
                <vs-avatar class="flex-shrink-0 border-2 border-white" :avatar="avatar" :size="45" />
                <vs-indicator class="absolute bottom-0 right-0 border-2 border-white rounded-full" color="primary" :size="10" />
              </div>
              <div class="text-xs">
                Geronimo
              </div>
            </div>
            <div class="flex flex-col items-center mx-2">
              <div class="relative">
                <vs-avatar class="flex-shrink-0 border-2 border-white" :avatar="avatar" :size="45" />
                <vs-indicator class="absolute bottom-0 right-0 border-2 border-white rounded-full" color="primary" :size="10" />
              </div>
              <div class="text-xs truncate w-12">
                Daniel Jimenez
              </div>
            </div>
          </div>
          <vs-button class="bg-gradient-to-br from-primary to-secondary" iconName="bi:chat-right-dots" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useSubscription, gql, useClientHandle, useQuery } from '@urql/vue'
import axios from 'axios'
import avatarCell from '@/components/avatarCell.vue'
import bus from '@/assets/bus.ts'
import school from '@/assets/school.svg'
import house from '@/assets/house.svg'
import avatar from '@/assets/avatar.jpg'


const getPositions = gql`
  subscription getPositions($deviceid:Int!) {
  tc_positions(where: {deviceid: {_eq: $deviceid}}, limit: 1, order_by: {servertime: desc}) {
    deviceid
    servertime
    latitude
    longitude
    speed
    course
  }
}
`
const getDirection = (heading: number) => {
  heading -= 25
  if (heading < 0) heading += 360
  const directions = ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw']
  const index = 4
  const remainder = heading - 45 * index
  const direction = directions[index]
  return { direction, remainder: heading+ 180 }
}   

const getCarDiv = (heading: number) => {
  const { direction, remainder } = getDirection(heading)
  if (!bus[direction]) console.info(heading, direction)
  return {
    html:`<img class="w-12 h-12" src="${bus[direction]}" />`,
    remainder
  }
}

export default defineComponent({
  name: 'Map',
  components: {
  },
  async setup() {
    const deviceids = ref([9])
    const markers = ref([])
    const polyLines = ref()
    const timer = ms => new Promise(res => setTimeout(res, ms))
    watch (polyLines, async () => {
      for (const [index,point] of polyLines.value.entries()) {
        if (index % 10 === 0) {
          let angle = index ? Math.atan2((point[0] - polyLines.value[index - 1][0]), (point[1] - polyLines.value[index - 1][1])) * 180 / Math.PI : 0
          const parsedAsset = {
            deviceid: 9,
            course: angle,
            longitude: point[0],
            latitude: point[1],
          }
          handleAsset(parsedAsset)
        }
        await timer(100)
      }
    })
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const parsedAsset = {
          id: 'me',
          name: '',
          html: `
          <div class="h-10 w-10 rounded-full bg-primary bg-opacity-20 relative flex justify-center items-center">
            <div class="h-3 w-3 rounded-full bg-primary" />
          </div>
          `,
          heading: 0,
          coordinates: [position.coords.longitude, position.coords.latitude],
        }
        markers.value.push(parsedAsset)
      })
    }
    // markers.value.push({
    //   id: 'daniel',
    //   name: '',
    //   html: `
    //   <div class="h-3 w-3 rounded-full bg-black relative flex justify-center items-center">
    //     <div class="h-1 w-1 rounded-full bg-white" />
    //   </div>
    //   `,
    //   heading: 0,
    //   coordinates: [-75.4782652, 6.0899291],
    // })
    markers.value.push({
      id: 'house',
      name: '',
      html: `<img width="25" src="${house}" />`,
      heading: 0,
      coordinates: [-75.4383888, 6.0328043],
    })
    const { html, remainder } = getCarDiv(0)
    markers.value.push({
      id: 9,
      name: '',
      html, // `<div class="rounded-full bg-no-repeat bg-cover border-2 border-primary" style="background-image: url('${avatar}'); width: 40px; height: 40px" />`,
      heading: remainder,
      coordinates: [-75.4239086, 6.034877],
    })
    markers.value.push({
      id: 'school',
      name: '',
      html: `<img width="60" src="${school}" />`,
      heading: 0,
      coordinates: [-75.540367, 6.156182],
    })
    markers.value.push({
      id: 'destination',
      name: '',
      html: `<div class="flex bg-white items-center transl translate-x-1/2 -translate-y-1/2"><div class="bg-gradient-to-t from-primary via-primary to-secondary text-on-primary text-center w-10"><div class="font-bold text-lg h-5">34</div> <div class="text-xs">min</div></div><div class="px-2 font-bold w-40 leading-tight">Columbus School</div></div>`,
      heading: 0,
      coordinates: [-75.540367, 6.156182],
    })
    markers.value.push({
      id: 'origin',
      name: '',
      html: `<div class="flex bg-white items-center translate-x-1/2 mb-2 ml-2"><div class="bg-gradient-to-t from-primary via-primary to-secondary text-on-primary flex flex-col items-center w-2 h-full"></div><div class="px-2 font-bold w-40 leading-tight py-1">Condominio Campestre Heliconias</div></div>`,
      heading: 0,
      coordinates: [-75.4383888, 6.0328043],
    })
    for (const deviceid of deviceids.value) {
      const variablesRef = ref({ deviceid: deviceid })
      const subscriptionResult = useSubscription({
        query: getPositions,
        variables: variablesRef
      })
      // const forcedFollowedAsset = ref(null)
      watch(subscriptionResult.data, async (data) => {
        console.info(subscriptionResult.data)
        const assetInfo = data && data['tc_positions'] && data['tc_positions'][0]
        handleAsset(assetInfo)
      })
    }
    const handleAsset = (assetInfo) => {
      const { html, remainder } = getCarDiv(assetInfo.course)
      const parsedAsset = {
        id: assetInfo.deviceid,
        name: assetInfo.deviceid,
        html,
        heading: remainder,
        coordinates: [assetInfo.longitude, assetInfo.latitude],
      }
      // console.info(parsedAsset)
      const index = markers.value.findIndex(marker => {
        return marker.id === assetInfo.deviceid
      })
      console.info(index)
      if (index === -1) {
        markers.value.push(parsedAsset)
      } else {
        markers.value[index] = parsedAsset
      }
    }
    const url = `https://route.isaga.co/route/v1/driving/-75.540367,6.156182;-75.4239086,6.034877?geometries=geojson&overview=full`
    const response = await axios.get(url)
    polyLines.value = response.data.routes[0].geometry.coordinates.reverse()
    const paths = [
      {
        id: '1',
        name: 'Ruta 1',
        color: '#0f39d3',
        polyLines: polyLines.value
      }
    ]
    // const routePolyLine = polyLine.encode(coordinates)
    const columns = [
      {
        field: 'name',
        headerName: 'Nombre',
        headerClass: 'bg-primary',
        cellComponent: avatarCell,
      },
      {
        field: 'state',
        headerName: 'Estado',
        // cellComponent: avatarCell,
      }
    ]
    const rows = [
      {
        name: 'Gerónimo Aristizábal Duque',
        state: '1A',
      }
    ]
    const expanded = ref(false)
    return { avatar, markers, paths, columns, rows, expanded }
  }
})
</script>

<style scoped>
.bar::-webkit-scrollbar-thumb
{
  cursor: pointer;
  border-radius: 3px;
  visibility: hidden;
}
</style>