<template>
<div>
  <vs-card>
    <div class="flex">
      <div class="font-semibold text-primary"> Transmisión Diaria </div>
      <!-- <vs-progress-bar :percentage="80" /> -->
    </div>
    <vs-chart type="line" :data="data"/>
  </vs-card>
</div>
</template>


<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Dashboard',
  components: {
  },
  setup() {
    const data = ref({
      labels: ['15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
      datasets: [
        {
          label: 'Diario',
          fill: false,
          borderColor: '#0f39d3AA',
          tension: .4,
          data: [85, 80, 85, 83, 92, 100, 100, 90, 75, 82, 100, 98, 98, 80, 85, 90]
        },
      ]
    })
    return { data }
  }
})
</script>
