<template>
  <div>
    <keep-alive>
      <vs-map
        :markers="markers"
        :forcedFollowedAsset="forcedFollowedAsset"
      >
      </vs-map>
    </keep-alive>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useSubscription, gql, useClientHandle, useQuery } from '@urql/vue'
import car from '@/assets/car.ts'

const getPositions = gql`
  subscription getPositions($deviceid:Int!) {
  tc_positions(where: {deviceid: {_eq: $deviceid}}, limit: 1, order_by: {servertime: desc}) {
    deviceid
    servertime
    latitude
    longitude
    speed
    course
  }
}
`

const getCarDiv = (color: string) => {
  return `
  <div class="text-white"; filter: drop-shadow(0px 0px 5px #1c1c1c)">
    ${car.svg}
  </div>
`
}

export default defineComponent({
  name: 'Map',
  components: {
  },
  setup() {
    const deviceids = ref([9])
    const markers = ref([])
    for (const deviceid of deviceids.value) {
      const variablesRef = ref({ deviceid: deviceid })
      const subscriptionResult = useSubscription({
        query: getPositions,
        variables: variablesRef
      })
      // const forcedFollowedAsset = ref(null)
      watch(subscriptionResult.data, async (data) => {
        const assetInfo = data && data['tc_positions'] && data['tc_positions'][0]
        const parsedAsset = {
          id: assetInfo.deviceid,
          name: assetInfo.deviceid,
          html: getCarDiv('white'),
          heading: assetInfo.course,
          coordinates: [assetInfo.longitude, assetInfo.latitude],
        }
        const index = markers.value.findIndex(marker => {
          marker.id === assetInfo.deviceid
        })
        if (index === -1) {
          markers.value.push(parsedAsset)
        } else {
          markers.value[index] = parsedAsset
        }
      })
    }
    return { markers }
  }
})
</script>
