import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, isSupported } from "firebase/messaging"

 const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCKzi1Msr-dfO-waTtIKV5K4mjP7i57BIM",
  authDomain: "isaga-tracking.firebaseapp.com",
  databaseURL: "https://isaga-tracking-default-rtdb.firebaseio.com",
  projectId: "isaga-tracking",
  storageBucket: "isaga-tracking.appspot.com",
  messagingSenderId: "1070368585897",
  appId: "1:1070368585897:web:fe78389a9dc55a6af251b8",
  measurementId: "G-CZ0QLHNBZJ"
}
let apiURL = ''
if (process.env.NODE_ENV === 'production') {
  apiURL = 'https://us-central1-isaga-tracking.cloudfunctions.net/api'
} else {
  apiURL = 'http://localhost:5002/isaga-tracking/us-central1/api'
}
export { apiURL, FIREBASE_CONFIG }

export const firebaseApp = initializeApp(FIREBASE_CONFIG)
export const firebaseAuth = getAuth()
let messaging
if (process.env.NODE_ENV === 'production' && isSupported()) {
  messaging = getMessaging()
} else {
  messaging = null
}
export const firebaseMessaging = messaging
const firestore = getFirestore()
export const db = firestore
getAnalytics()

export const firebaseTools = {
  signInWith: async (providerType: string) => {
    let provider = null
    if (providerType === 'google') {
      provider = new GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    }
    if (providerType === 'facebook') {
      provider = new FacebookAuthProvider()
    }
    firebaseAuth.useDeviceLanguage()
    if (provider !== null) {
      try {
        const result = await signInWithPopup(firebaseAuth, provider)
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential?.accessToken
        const user = result.user
        return { user, token }
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        console.error(email, credential, errorCode, errorMessage)
      }
    }
    return { user: null, token: null }
  },
  getCurrentUserJWT: async () => {
    try {
      const user = await firebaseAuth.currentUser
      if (user) {
        const token = await user.getIdToken(true)
        return token
      }
    } catch (error) {
      console.log(error)
    }
  },
  signOut: async () => {
    await firebaseAuth.signOut()
    window.history.pushState('', '', process.env.BASE_URL)
  },
}
