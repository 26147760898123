<template>
  <div class="w-full flex">
    <vs-sidebar
      :pages="pages"
      :active-page="activePage"
      @page:select="handlePageSelect"
    >
      <template #header="{ isCollapsed }">
        <div class="flex items-center w-full justify-center h-20">
          <img src="@/assets/logo.svg" :width="isCollapsed ? 50 : 80"/>
        </div>
      </template>
    </vs-sidebar>
    <div class="h-screen overflow-y-auto sm:w-9/12 sm:min-w-150 flex-1 w-full">
      <Suspense>
        <router-view />
      </Suspense>
    </div>
  </div>
  <modals-container></modals-container>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeMount, onMounted } from 'vue'
import { firebaseAuth } from '@/services/firebase'
import { useAuthStore } from '@/stores'
import { useRouter, useRoute } from 'vue-router'
import { useStorage } from '@vueuse/core'

export default defineComponent({
  name: 'App',
  setup () {
    const authStore = useAuthStore()
    onMounted(() => {
      if (location.hash !== '') {
        let os = location.hash.slice(1, location.hash.length).split('/')[0]
        let version = location.hash.slice(1, location.hash.length).split('/')[1]
        if (os === 'android' || os === 'ios') {
          let system = `${os}/${version}/cordova.js`
          let cordovaScript = document.createElement('script')
          cordovaScript.setAttribute('src', system)
          cordovaScript.setAttribute('type', 'text/javascript')
          document.getElementsByTagName('head')[0].appendChild(cordovaScript)
          document.addEventListener('deviceready', () => {
            if (typeof navigator.splashscreen !== 'undefined') navigator.splashscreen.hide()
          })
        }
      }
    })
    onBeforeMount(async () => {
      if (firebaseAuth.currentUser) {
        const user = firebaseAuth.currentUser
        await authStore.setUser(user)
      }
      if (authStore.tokenObserver === null) {
        const unbind = firebaseAuth.onIdTokenChanged(async (user) => {
          if (user !== null) {
            await authStore.setUser(user)
          } 
          // TODO: logout if user === null
        })
        authStore.setTokenObserver(unbind)
      }
    })
    const router = useRouter()
    const route = useRoute()
    const pages = ref(
      [
        {
          name: 'Tablero',
          icon: 'whh:statistics',
          route: '/dashboard/',
          id: 'dashboard'
        },
        {
          name: 'Datos',
          icon: 'bx:bxs-data',
          id: 'table',
          group: true,
          pages: [
            {
              name: 'Rutas',
              id: 'routes',
              icon: 'eos-icons:route',
              solid: true,
              route: '/table/routes',
            },
            {
              name: 'Pasajeros',
              id: 'packages',
              icon: 'bi:file-person-fill',
              solid: true,
              route: '/table/parcels',
            },
            {
              name: 'Vehículos',
              id: 'vehicles',
              icon: 'fa-solid:shuttle-van',
              solid: true,
              route: '/table/vehicles',
            },
          ]
        },
        {
          name: 'Mapa',
          icon: 'bxs:map',
          route: '/map/',
          id: 'map'
        },
        {
          name: 'Optimización',
          icon: 'icon-park-outline:smart-optimization',
          route: '/optimization/',
          id: 'optimization'
        },
        {
          name: 'Chat',
          icon: 'bi:chat-square-dots-fill',
          route: '/chat/',
          id: 'chat'
        },
        {
          name: 'Novedades',
          icon: 'bi:calendar-event-fill',
          route: '/chat/',
          id: 'noti'
        },
        {
          name: 'Notificaciones',
          icon: 'ant-design:notification-filled',
          route: '/chat/',
          id: 'notifications'
        },
        {
          name: 'Matrículas',
          icon: 'clarity:list-solid',
          route: '/enrollment/',
          id: 'enrollment'
        },
        {
          name: 'Usuario',
          icon: 'carbon:user-avatar-filled',
          route: '/user/',
          id: 'user'
        },
        {
          name: 'Monitora',
          icon: 'fa-solid:bus-alt',
          route: '/vehicle/',
          id: 'vehicle'
        },
        {
          name: 'Login',
          icon: 'uis:graph-bar',
          route: '/login/',
          id: 'login'
        },
      ]) as any
    const activePage = ref('')
    watch(route, () => {
      console.info(route.name)
      activePage.value = (route.name as string) || ''
    })
    const handlePageSelect = (page: Record<string, string>) => {
      if (page.group) {
        console.info(page.pages[0].route)
        if (page.pages) {
          router.push(page.pages[0]?.route)
        }
      }
      if (!page.route) return
      router.push(page.route)
    }
    return { pages, activePage, handlePageSelect, authStore }
  }
})
</script>