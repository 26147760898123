<template>
  <vs-login 
    href="https://cuidarte.co/protecciondatos.pdf"
    @signIn="signIn"
  >
    <template #background>
      <div class="absolute left-0 top-0 w-full h-full bg-gradient-to-tl from-primary to-secondary animate-bg z-0" />
    </template>
    <template #logo>
      <img alt="logo" :src="logo" />
    </template>
  </vs-login>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAuthStore } from '@/stores'
import { useRouter } from 'vue-router'

import logo from '@/assets/logo.svg'

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const signIn = async (provider:string) => {
      await authStore.signIn(provider)
      router.push('/')
    }
    return { logo, signIn }
  }
})
</script>
