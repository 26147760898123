import { createApp } from 'vue'
import App from './App.vue'

import vuesaga, { initConfig } from '@isagaco/vuesaga'
import '@isagaco/vuesaga/dist/style.css'

import axios from 'axios'
axios.defaults.baseURL =
  import.meta.env.DEV
    ? 'http://localhost:5001/backup-1dad9/us-central1/api/'
    : 'https://us-central1-backup-1dad9.cloudfunctions.net/api/'

import './index.css'
import '@purge-icons/generated'
import urql, { defaultExchanges, subscriptionExchange } from '@urql/vue'
import { subscriptionClient } from './services/hasura'
import { vfmPlugin } from '@isagaco/vuesaga'

import router from './router'
import { createPinia } from 'pinia'

initConfig()
createApp(App)
  // @ts-ignore
  .use(vuesaga)
  .use(router)
  .use(createPinia())
  .use(urql, {
    url: 'https://db.rutasegura.co/v1/graphql',
    fetchOptions: {
      headers: {
        'content-type': 'application/json',
        'x-hasura-admin-secret':'hLg5yqDkrCJ6AkjHAam'
      },
    },
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription: (operation) => subscriptionClient.request(operation),
      }),
    ],
  })
  .use(vfmPlugin)
  .mount('#app')
