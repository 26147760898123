<template>
  <div class="relative float-left transition-all cursor-pointer">
    <span class="text-center absolute w-full top-1/2 -translate-y-1/2 text-sm">
      {{ slotProps.data[slotProps.field] }}%
    </span>
    <svg
      class="donut-svg drop-shadow-[0px_0px_3px_rgba(0,0,0,0.8)]"
      width="60"
      height="60"
      xmlns="http://www.w3.org/2000/svg"
    >
     <g>
       <circle
        id="circle"
        :style="`stroke-dashoffset: 0`"
        :class="`stroke-current text-${slotProps.data[slotProps.field] >= 80 ? 'primary' : 'primary'}-100`"
        r="22.28"
        cy="31"
        cx="31"
        stroke-width="8"
        fill="none"
      />
      <circle
        id="circle"
        :style="`stroke-dashoffset: ${140 * (1- slotProps.data[slotProps.field] / 100)}`"
        :class="`donut stroke-current text-${slotProps.data[slotProps.field] >= 80 ? 'primary' : 'primary'}-500`"
        r="22.28"
        cy="31"
        cx="31"
        stroke-width="8"
        fill="none"
      />
     </g>
    </svg>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  export default defineComponent({
    name: 'doughnutTableCell',
    props: {
      slotProps: { type: Object, default: () => {} }
    },
    setup(props) {
      return {
      }
    }
  })
</script>

<style>


.donut-svg {
  transform: rotate(-90deg);
}

.donut {
  stroke-dasharray: 140;
  animation: donut 1s ease-out forwards;
}

@keyframes donut {
  from {
    stroke-dashoffset: 140;
  }
}
</style>