import dualCell from '@/components/dualCell.vue'
import percentageCell from '@/components/percentageCell.vue'
import avatarCell from '@/components/avatarCell.vue'
import statusCell from '@/components/statusCell.vue'
import doughnutCell from '@/components/doughnutCell.vue'
import tagCell from '@/components/tagCell.vue'
export default {
  routes: {
    actions: {
      passengers: {
        icon: 'healthicons:child-program',
        emit: 'table:map',
        name: 'Pasajeros'
      },
      share: {
        icon: 'bxs:map',
        emit: 'table:map',
        name: 'Mapa'
      },
    },
    columns: [
      {
        field: 'name',
        headerName: 'Nombre',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: dualCell,
        editable: true
      },
      {
        field: 'contactName_contactPhoneNumber',
        headerName: 'Contacto_Teléfono',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: dualCell,
        editable: true
      },
      {
        field: 'plate',
        headerName: 'Vehículo',
        sortable: true,
        filter: true,
        resizable: true,
        class: 'font-bold',
        editable: true
      },
      {
        field: 'occupancy',
        headerName: 'Ocupación',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: doughnutCell,
        editable: true
      },
      {
        field: 'tags',
        headerName: 'Etiquetas',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: tagCell,
        editable: false
      },
    ],
  },
  vehicles: {
    actions: {
      share: {
        icon: 'bxs:bus',
        emit: 'table:map',
        name: 'Rutas'
      },
    },
    columns: [
      {
        field: 'name',
        headerName: 'Vehículo',
        sortable: true,
        filter: true,
        resizable: true,
        class: 'font-bold',
        editable: true
      },
      {
        field: 'capacity',
        headerName: 'Capacidad',
        sortable: true,
        filter: true,
        class: 'font-bold',
        resizable: true,
        editable: true
      },
      {
        field: 'gps',
        headerName: 'GPS',
        sortable: true,
        filter: true,
        cellComponent: statusCell,
        resizable: true,
        editable: true
      },
      {
        field: 'tags',
        headerName: 'Etiquetas',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: tagCell,
        editable: false
      },
    ],
  },
  parcels: {
    actions: {
      share: {
        icon: 'bxs:bus',
        emit: 'table:map',
        name: 'Rutas'
      },
      2: {
        icon: 'raphael:parent',
        emit: 'table:map',
        name: 'Papas'
      },
    },
    columns: [
      {
        field: 'name_documentID',
        headerName: 'Nombre_Documento',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: avatarCell,
        editable: true
      },
      {
        field: 'address_hint',
        headerName: 'Dirección',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: dualCell,
        editable: true
      },
      {
        field: 'tags',
        headerName: 'Etiquetas',
        sortable: true,
        filter: true,
        resizable: true,
        cellComponent: tagCell,
        editable: false
      },
    ],
  }
}