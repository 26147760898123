<template>
  <div v-if="slotProps.data">
    <div
      v-if="hasValue(slotProps.data[slotProps.field])"
      class="flex"
    >
      <div
        v-for="index in (Math.min(array.length, 2))"
        :key="index"
        class="text-sm bg-primary-container text-on-primary-container p-0.5 rounded px-1 mx-0.5"
      >
          #{{ array[index - 1] }}
      </div>
      <div
        v-if="array.length > 2"
        class="text-sm bg-primary-container text-on-primary-container p-0.5 rounded px-1 mx-0.5"
      >
        +{{ array.length - 2}}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  export default defineComponent({
    name: 'plateCell',
    props: {
      slotProps: { type: Object, default: () => {} }
    },
    setup(props) {
      const array = computed(() => {
        return props.slotProps.data[props.slotProps.field].split(',')
      })
      const hasValue = (value) => {
        return typeof value !== 'undefined'
      }
      const getColors = (value) => {
        if (value) {
          return 'bg-success text-on-success'
        } else {
          return 'bg-primary-container text-on-primary-container'
        }
      }
      return {
        array,
        hasValue,
        getColors
      }
    }
  })
</script>