<template>
  <div class="flex items-center h-full">
    <div>
      <div v-if="slotProps.data[slotProps.field]" class="font-bold w-full text-primary leading-tight">
        {{ slotProps.data[slotProps.field].split('_')[0] }}
      </div>
      <div v-if="slotProps.data[slotProps.field]" class="text-xs w-full opacity-75 leading-tight">
        {{ slotProps.data[slotProps.field].split('_')[1] }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'dualTableCell',
    props: {
      slotProps: { type: Object, default: () => {} }
    },
    setup(props) {
        return {
        }
    }
  })
</script>