import { SubscriptionClient } from 'subscriptions-transport-ws'

export const subscriptionClient = new SubscriptionClient('wss://db.rutasegura.co/v1/graphql', {
  reconnect: true,
  connectionParams: {
    headers: {
      'x-hasura-admin-secret': 'hLg5yqDkrCJ6AkjHAam',
    }
  }
});
