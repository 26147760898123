<template>
  <div class="p-5 pt-2 relative pb-10">
    <div
      class="font-bold text-4xl py-1 px-4 shadow rounded flex justify-center items-center cursor-pointer"
      @click="selectingRoute = true; showModal = true; currentStopID = null"
    >
      {{ currentRoute.name }}
      <vs-icon class="ml-4" name="charm:link-external" />
    </div>
    <vs-card
      v-for="stop in stops"
      :key="stop.id"
      :class="`my-4 relative transition-all pb-10 overflow-hidden ${stop.alert ? 'animate-glow' : ''}`"
      @click="selectingRoute = false; showModal = true; currentStopID = stop.id"
    >
      <div v-if="stop.alert" class="w-full h-full absolute top-0 right-0 bg-primary bg-opacity-10" />
      <div class="flex items-center">
        <vs-avatar class="flex-shrink-0" :avatar="avatar" :size="42" />
        <div class="ml-5">
          <div class="text-xl mt-2 ml">
            {{ stop.name }}
          </div>
          <div class="opacity-75">
            {{ stop.hint }}
          </div>
        </div>
      </div>
      <vs-badge
        v-if="stop.state"
        class="absolute bottom-0 right-0 left-0 px-3 flex justify-between"
        :color="packageStates[stop.state]?.color"
      >
        <div>
          {{ packageStates[stop.state]?.label }}
        </div>
        <div v-if="stop.waitTime" class="flex items-center">
          <div class="ml-2">{{ dateTimeFormat.format(new Date(stop.waitTime * 1000)) }}</div>
        </div>
        <vs-icon color="on-primary" name="bi:check-all" :size="24" />
      </vs-badge>
    </vs-card>
  </div>
  <div class="bg-white sticky bottom-0 right-0 w-full">
    <div class="w-full flex justify-between items-center">
      <vs-button v-if="!currentRoute.streaming" class="h-12 w-36" kind="success" iconName="clarity:play-solid" text="Comenzar" @click="currentRoute.streaming = !currentRoute.streaming"/>
      <vs-button v-else class="h-12 w-36" kind="error" iconName="clarity:stop-solid" text="Finalizar" @click="currentRoute.streaming = !currentRoute.streaming"/>
      <div class="flex flex-grow flex-col h-12 items-center justify-center items-center px-6 pb-2">
        <div class="flex mt-2">
          <vs-icon
            v-for="star in 3"
            :key="star"
            name="ant-design:star-filled"
            color="tertiary"
          />
          <vs-icon
            v-for="star in 2"
            :key="star"
            name="ant-design:star-outlined"
            color="tertiary"
          />
        </div>
      </div>
      <vs-button
      class="h-12"
      :kind="routeStates[currentRoute.state]?.color"
      :iconName="routeStates[currentRoute.state]?.icon"
      @click="showModal = true"
      />
    </div>
  </div>
  <vs-modal 
    v-model="showModal"
    buttonless
    @close="currentStopID = null; selectingRoute = false"
  >
    <template #title>{{ currentStop.name || 'EIN017' }}</template>
    <template #default="{close}">
      <div
        v-if="selectingRoute"
        class="flex flex-col flex-grow"
      >
        <vs-button
          v-for="(route, routeID) of routes"
          :key="route.id"
          :iconName="route.icon"
          :iconSize="48"
          kind="flat"
          class="text-4xl py-5 bg-opacity-75 my-0.5 !shadow"
          @click="currentRouteID = routeID; close()"
        >
          {{ route.name }}
        </vs-button>
      </div>
      <div
        v-else
        class="flex flex-col flex-grow"
      >
        <vs-button
          v-for="(state, stateID) of (currentStopID ? packageStates : routeStates)"
          :key="state.id"
          :iconName="state.icon"
          :iconSize="48"
          :kind="state.color"
          class="text-4xl py-5 bg-opacity-75 my-0.5"
          @click="handleState(stateID, close)"
        >
          {{ state.label }}
        </vs-button>
      </div>
    </template>
  </vs-modal>
</template>


<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import axios from 'axios'
import avatar from '@/assets/avatar.jpg'
import { vsModal } from '@isagaco/vuesaga'
import { createToast, withProps, vsToast } from '@isagaco/vuesaga'

export default defineComponent({
  name: 'Dashboard',
  components: {
  },
  setup() {
    const stopArray = [
      {
        name: 'Geronimo Aristizabal',
        coordinates: [6.4 -75.3],
        hint: 'Condominio Campestre Heliconias, Casa 8',
        address: 'Cl 27#13-221',
        id: '1',
        waiting: false
      },
      {
        name: 'Matilda',
        coordinates: [6.4 -75.3],
        hint: 'Condominio Campestre Heliconias, Casa 8',
        address: 'Cl 27#13-221',
        id: '2',
        alert: true,
      },
      {
        name: 'Geronimo Aristizabal',
        coordinates: [6.4 -75.3],
        hint: 'Condominio Campestre Heliconias, Casa 8',
        address: 'Cl 27#13-221',
        id: '3',
        alert: true,
      },
      {
        name: 'Geronimo Aristizabal',
        coordinates: [6.4 -75.3],
        hint: 'Condominio Campestre Heliconias, Casa 8',
        address: 'Cl 27#13-221',
        id: '4'
      },
      {
        name: 'Geronimo Aristizabal',
        coordinates: [6.4 -75.3],
        hint: 'Condominio Campestre Heliconias, Casa 8',
        address: 'Cl 27#13-221',
        id: '5'
      },
      {
        name: 'Geronimo Aristizabal',
        coordinates: [6.4 -75.3],
        hint: 'Condominio Campestre Heliconias, Casa 8',
        address: 'Cl 27#13-221',
        id: '6'
      },
    ]
    const stops = ref(stopArray.reduce((obj, item) => (obj[item.id] = {...item}, obj) ,{}))
    const currentStop = computed(() => stops.value[currentStopID.value] || {})
    const routes = ref({
      1: {
        name: 'Ruta 1 AM',
        id: '1',
        state: 'onTime'
      },
      2: {
        name: 'Ruta 2',
        id: '2',
        state: 'onTime'
      },
      3: {
        name: 'Ruta 3',
        id: '3',
        state: 'onTime'
      },
      4: {
        name: 'Ruta 4',
        id: '4',
        state: 'onTime'
      },
      5: {
        name: 'Ruta 5',
        id: '5',
        state: 'onTime'
      },
      6: {
        name: 'Ruta 6',
        id: '6',
        state: 'onTime'
      },
      7: {
        name: 'Ruta 7',
        id: '7',
        state: 'onTime'
      },
      8: {
        name: 'Ruta 8',
        id: '8',
        state: 'onTime'
      },
      9: {
        name: 'Ruta 9',
        id: '9',
        state: 'onTime'
      },
    })
    const currentRoute = computed(() => routes.value[currentRouteID.value] || {})
    const setWaiting = (stopID, value) => {
      stops.value[stopID].waiting = value
    }
    setInterval(() => {
      for (const stopID of Object.keys(stops.value)) {
        if (stops.value[stopID].waiting) {
          if(!stops.value[stopID].waitTime) {
            stops.value[stopID].waitTime = 0
          }
          stops.value[stopID].waitTime++
        }
      }
    }, 1000)
    const dateTimeFormat = new Intl.DateTimeFormat('default', { 
      minute: 'numeric',
      second: 'numeric',
    })
    const packageStates = {
      waiting: {
        label: 'En espera',
        icon: 'ant-design:clock-circle-filled',
        color: 'tertiary'
      },
      absent: {
        label: 'Ausente',
        icon: 'akar-icons:circle-x-fill',
        color: 'inverse-surface'
      },
      present: {
        label: 'A bordo',
        icon: 'icomoon-free:enter',
        color: 'primary'
      },
      delivered: {
        label: 'Entregado',
        icon: 'akar-icons:circle-check-fill',
        color: 'success'
      }
    }
    const routeStates = {
      onTime: {
        label: 'A tiempo',
        icon: 'akar-icons:circle-check-fill',
        color: 'success'
      },
      traffic: {
        label: 'Congestión vehicular',
        icon: 'ph:traffic-cone-fill',
        color: 'tertiary'
      },
      delay: {
        label: 'Ruta retrasada',
        icon: 'ant-design:clock-circle-filled',
        color: 'tertiary'
      },
    }
    const showModal = ref(false)
    const currentRouteID = ref('1')
    const currentStopID = ref()
    const handleState = (stateID, close) => {
      if (!currentStopID.value) {
        routes.value[currentRouteID.value].state = stateID
        close()
        return
      }
      if (stateID === 'waiting') {
        setWaiting(currentStopID.value, true)
      } else {
        setWaiting(currentStopID.value, false)
      }
      stops.value[currentStopID.value].state = stateID
      close()
    }
    return { avatar, stops, currentStop, currentRoute, packageStates, routeStates, routes, setWaiting, dateTimeFormat, showModal, currentStopID, currentRouteID, handleState }
  }
})
</script>
