import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { $vfm } from '@isagaco/vuesaga'
import Home from '../views/Home.vue'
import login from '../views/login.vue'
import map from '../views/map.vue'
import optimization from '../views/optimization.vue'
import table from '../views/table.vue'
import enrollment from '../views/enrollment.vue'
import chat from '../views/chat.vue'
import dashboard from '../views/dashboard.vue'
import user from '../views/user.vue'
import vehicle from '../views/vehicle.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login/',
    name: 'login',
    component: login,
  },
  {
    path: '/map/',
    name: 'map',
    component: map,
  },
  {
    path: '/optimization/',
    name: 'optimization',
    component: optimization,
  },
  {
    path: '/chat/',
    name: 'chat',
    component: chat,
  },
  {
    path: '/table/:tableID',
    name: 'table',
    component: table,
  },
  {
    path: '/enrollment/',
    name: 'enrollment',
    component: enrollment,
  },
  {
    path: '/dashboard/',
    name: 'dashboard',
    component: dashboard,
  },
  {
    path: '/user/',
    name: 'user',
    component: user,
  },
  {
    path: '/vehicle/',
    name: 'vehicle',
    component: vehicle,
  },
  // {
  //   path: '/admin/tags',
  //   component: () => import('../views/admin/tags.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  if ($vfm.openedModals.length > 0) {
    console.info(Object.assign({},$vfm.openedModals))
    $vfm.hideAll()
    return false
  }
})

export default router
