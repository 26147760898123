<template>
  <div v-if="slotProps.data">
    <div
      v-if="hasValue(slotProps.data[slotProps.field])"
      :class="`w-32 text-center rounded-sm ${getColors(slotProps.data[slotProps.field])} opacity-75 font-bold`"
    >
      {{ slotProps.data[slotProps.field] ? 'Conectado' : 'Desconectado' }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'plateCell',
    props: {
      slotProps: { type: Object, default: () => {} }
    },
    setup(props) {
      const hasValue = (value) => {
        return typeof value !== 'undefined'
      }
      const getColors = (value) => {
        if (value) {
          return 'bg-success text-on-success'
        } else {
          return 'bg-primary-container text-on-primary-container'
        }
      }
      return {
        hasValue,
        getColors
      }
    }
  })
</script>