import { defineStore } from 'pinia'
import { firebaseTools } from '@/services/firebase'
import { User, Unsubscribe } from 'firebase/auth'

export const useAuthStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'auth',
  // a function that returns a fresh state
  state: () => ({
    instanceID: '',
    user: null as User | null,
    token: null as string | null,
    jwt: '',
    tokenObserver: null as Unsubscribe | null,
  }),
  // optional getters
  getters: {
    isAuthenticated: (state) => !!state.user,
  },
  // optional actions
  actions: {
    setTokenObserver(observer: Unsubscribe) {
      this.tokenObserver = observer
    },
    setInstanceID(instanceID: string) {
      this.instanceID = instanceID
    },
    async signIn(provider: string) {
      const { user, token } = await firebaseTools.signInWith(provider)
      if (user && token) {
        this.user = user
        this.token = token
      }
    },
    async setUser(user: User) {
      this.user = user
      this.jwt = await firebaseTools.getCurrentUserJWT() as string
    }
  }
})
